<!--
 * @Author: 张博洋
 * @Date: 2022-02-15 10:26:24
 * @LastEditTime: 2022-03-05 14:06:10
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <van-tabs @change="tabsClick"
              :ellipsis="false"
              :swipeable="true"
              v-model="query.status">
      <van-tab v-for="(item,index) in statusList"
               :title="item.text"
               :name="item.value"
               :key="index">
        <list-page v-if="index + 1 === query.status"  :query="query"
                    ref="list">
        </list-page>
      </van-tab>
    </van-tabs>
    <!-- <div class="flex1 bg-bg">
      <list-page :query="query"
                 ref="list">
      </list-page>
    </div> -->
  </div>
</template>

<script>
import vatTab from '@/mixin/Tab.js'
import DropdownMenu from '@/mixin/DropdownMenu.js'

import listPage from './views/list'
export default {
  mixins: [DropdownMenu, vatTab],
  components: {
    listPage,
  },
  data() {
    return {
      show: false,
      query: {
        status: 1,
        patientId: this.$route.query.patientId,
      },

      statusList: [
        {
          text: '待随访',
          value: 1,
        },
        {
          text: '随访中',
          value: 2,
        },
        {
          text: '已随访',
          value: 3,
        },
        {
          text: '已取消',
          value: 4,
        },
      ],
    }
  },
  methods: {
    tabsClick(val) {
      console.log(val)
      this.query.status = val
    },
  },
}
</script>

<style lang="scss" scoped>
.van-tabs {
        flex:1;
        display:flex;
        flex-direction:column;
        overflow:hidden;
        /deep/.van-tabs__wrap {
          min-height:50px;
        }
        /deep/.van-tabs__content {
          flex: 1;
          overflow: auto;
        }
    }
    
</style>