<!--
 * @Author: 张博洋
 * @Date: 2022-02-21 14:09:53
 * @LastEditTime: 2022-03-03 10:22:09
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/views/follow/views/card.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <template v-if="status ===1">
      <div class="card"
           v-for="(item,index) in dataList"
           :key="index">
        <div class="top">
          <div>
            <span class="name">{{item.patientName}}</span>
            <span class="sex ml10">{{item.patientSex === 1 ? '男' : '女'}}</span>
            <span class="sex ml10">{{item.patientAge}} 岁</span>
          </div>
          <div class="btn"
               @click="checkDetail(item.id)">查看详情
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="plan-name">{{item.planName}}</div>
        <div class="bottom">
          <span v-if="item.expectTimeStr === 0 || item.expectTimeStr === 1 || item.expectTimeStr === 2 || item.expectTimeStr < 0"
                class="format mr8"
                :class="transformStrColor(item.expectTimeStr)">{{transformStr(item.expectTimeStr)}}</span>
          <span class="time">{{$.replyTimeToDay(item.expectTime)}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card"
           v-for="(item,index) in dataList"
           :key="index">
        <div class="top">
          <div>
            <span class="name">{{item.patientName}}</span>
            <span class="sex ml10">{{item.patientSex === 1 ? '男' : '女'}}</span>
            <span class="sex ml10">{{item.patientAge}} 岁</span>
          </div>
          <div class="btn"
               @click="checkDetail(item.id)">查看详情
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="plan-name">描述：{{item.imDesc}}</div>
        <div class="bottom"
             style="justify-content: space-between;">
          <span class="time">{{item.status === 4 ? $.replyTime(item.endTime) : $.replyTime(item.beginTime)}}</span>
          <span :style="{color:transformStateColor(item.status)}">{{transformState(item.status)}}</span>
        </div>
      </div>
    </template>

  </div>

</template>

<script>
export default {
  props: ['dataList', 'status'],
  methods: {
    transformStateColor(status) {
      switch (status) {
        case 1: {
          return ''
        }
        case 2: {
          return '#FF8200'
        }
        case 3: {
          return '#1AB370'
        }
        case 4: {
          return '#C0C0C0'
        }
      }
    },
    transformState(status) {
      switch (status) {
        case 1: {
          return '待随访'
        }
        case 2: {
          return '随访中'
        }
        case 3: {
          return '已随访'
        }
        case 4: {
          return '已取消'
        }
      }
    },
    transformStr(expectTimeStr) {
      if (expectTimeStr == 0) {
        return '今天'
      } else if (expectTimeStr == 1) {
        return '明天'
      } else if (expectTimeStr == 2) {
        return '后天'
      } else if (expectTimeStr < 0) {
        return '超时未随访'
      }
      // switch (expectTimeStr) {
      //   case 0: {
      //     return '今天'
      //   }
      //   case 1: {
      //     return '明天'
      //   }
      //   case 2: {
      //     return '后天'
      //   }
      // }
    },
    transformStrColor(expectTimeStr) {
      if (expectTimeStr == 0) {
        return 'green'
      } else if (expectTimeStr == 1 || expectTimeStr == 2) {
        return 'grey'
      } else if (expectTimeStr < 0) {
        return 'red'
      }
    },
    checkDetail(id) {
      this.$router.push({
        name: 'follow.detail',
        query: {
          taskId: id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card:first-of-type {
  margin-top: 0;
}
.card {
  background: #ffffff;
  border-radius: 8px;
  padding: 19px 16px;
  margin: 8px 0;
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .age,
    .sex {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .btn {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 33px;
    }
  }
  .plan-name {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin: 12px 0;
  }
  .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 14px;
    }
    .format {
      padding: 2px 8px;
      text-align: center;
      border-radius: 4px;
      font-size: 12px;
    }
    .green {
      background: rgba(26, 179, 112, 0.1);
      color: rgba(26, 179, 112, 1);
    }
    .grey {
      background: rgba(245, 245, 245, 1);
      color: rgba(102, 102, 102, 1);
    }
    .red {
      background: rgba(255, 77, 79, 0.1);
      color: #ff4d4f;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
