<!--
 * @Author: 张博洋
 * @Date: 2021-08-06 23:49:00
 * @LastEditTime: 2022-02-21 14:39:48
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/views/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <div class="pr full over-y wrap">
    <div class="pd8">
      <div class="list"
           v-if="recentList.length">
        <p>近3天待随访任务</p>
        <follow-card :dataList="recentList"
                     :status="query.status"></follow-card>
      </div>
      <div class="list"
           v-if="recent4List.length">
        <p>3天后待随访任务</p>
        <follow-card :dataList="recent4List"
                     :status="query.status"></follow-card>
      </div>
      <div class="list"
           v-if="query.status !==1">
        <follow-card :dataList="elseList"
                     :status="query.status"></follow-card>
      </div>
    </div>
    <div v-if="!recentList.length && !recent4List.length && !elseList.length"
         class="empty full-h">
      <img width="120"
           src="../../../static/img/order-empty.png">
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
import button from '@/mixin/Button.js'
import followCard from './card.vue'
export default {
  mixins: [button],
  props: {
    query: {
      type: Object,
    },
  },
  components: {
    followCard,
  },
  watch: {
    'query.status': {
      handler(val) {
        console.log('==========',val)
        if (val !== 1) {
          this.recent4List = []
          this.recentList = []
        }
      },
      immediate:true
    },
  },
  data() {
    return {
      recentList: [],
      recent4List: [],
      elseList: [],
      empty: false,
    }
  },
  created() {
    console.log(this.query.status)
    this.getData()
  },
  activated(){
    this.getData() 
  },
  computed: {},
  methods: {
    getData() {
      this.$axios({
        url: 'visitTask/list',
        data: this.query,
        elseData: {
          ajaxLoading: true,
          loading: true,
          errorTip: false,
        },
      })
        .then((res) => {
          console.log(this.query.status)
          if (this.query.status !== 1) {
            this.elseList = [...res.d.recentList]
          }
          this.recent4List = [...res.d.recent4List]
          this.recentList = [...res.d.recentList]
        })
        .catch((e) => {
          this.error = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  .list {
    p {
      font-size: 14px;
      color: #333;
      padding: 10px 12px 16px;
    }
    .card {
      background: #ffffff;
      border-radius: 8px;
      padding: 19px 16px;
      margin: 8px 0;
      .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f5f5f5;
        .name {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
        .age,
        .sex {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        .btn {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 33px;
        }
      }
      .plan-name {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin: 12px 0;
      }
      .bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font-size: 14px;
        }

        .green {
          background: rgba(26, 179, 112, 0.1);
          color: rgba(26, 179, 112, 1);
        }
        .grey {
          background: rgba(245, 245, 245, 1);
          color: rgba(102, 102, 102, 1);
        }
        .time {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 14px;
    }
    img {
      margin-top: calc(50% - 80px);
    }
    color: #999;
  }
}
</style>